
import React from 'react';
import {useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { logouts } from './proxyurls';
import logoutimage from '../assets/logout.png';


const Navbar = () => {
  const navigate = useNavigate();
  const {  logout } = useAuth();

  const handleLogout = async () => {
    try {
      const response = await fetch(logouts, {
        method: 'POST',
        credentials: 'include', 
      });

      if (response.ok) {
        logout();
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={{marginLeft:"91%"}}>
              <li className="nav-item dropdown">
                <button
                  
                  className="nav-link py-0"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="avatar avatar-md">
                    <img
                      src={logoutimage}
                      className="avatar-img"
                      alt="Admin"
                    />
                  </div>
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-end pt-0"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    <h6 className="dropdown-header bg-body-secondary text-body-secondary fw-semibold rounded-top mb-2">
                      Account
                    </h6>
                  </li>
                  <li>
                    <button className="dropdown-item" 
                    onClick={handleLogout}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="icon me-2"
                        role="img"
                        aria-hidden="true"
                      >
                        <polygon
                          fill="currentColor"
                          points="77.155 272.034 351.75 272.034 351.75 272.033 351.75 240.034 351.75 240.033 77.155 240.033 152.208 164.98 152.208 164.98 152.208 164.979 129.58 142.353 15.899 256.033 15.9 256.034 15.899 256.034 129.58 369.715 152.208 347.088 152.208 347.087 152.208 347.087 77.155 272.034"
                        />
                        <polygon
                          fill="currentColor"
                          points="160 16 160 48 464 48 464 464 160 464 160 496 496 496 496 16 160 16"
                        />
                      </svg>
                      Logout
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </>
  );
};

export default Navbar;
