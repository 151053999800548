import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { client, clientdelete } from './proxyurls';
import EditClient from './EditClient'; 
import AddClient from './AddClient'; 
import { useNavigate } from 'react-router-dom';
import Layout from './layout';

function Clients() {
  const [clients, setClients] = useState([]);
  const [editClientId, setEditClientId] = useState(null); 
  const [addingClient, setAddingClient] = useState(false); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [successMessage, setSuccessMessage] = useState(''); 
  const recordsPerPage = 12; 
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(client); 
        setClients(response.data); 
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const successMessage = localStorage.getItem('successMessage');
    if (successMessage) {
      setSuccessMessage(successMessage);
      localStorage.removeItem('successMessage');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    }
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this client?');
    if (!confirmDelete) return;

    try {
      await axios.delete(clientdelete(id));
      setClients(clients.filter((client) => client.id !== id));
      setSuccessMessage('Client deleted successfully');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-attorney/${id}`); 
  };

  const handleCancelEdit = () => {
    setEditClientId(null); 
  };

  const handleSaveEdit = () => {
    setEditClientId(null); 
    const fetchClients = async () => {
      try {
        const response = await axios.get(client); 
        setClients(response.data); 
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };
    fetchClients();
  };

  const handleAdd = () => {
    navigate('/add-attorney'); 
  };

  const handleCancelAdd = () => {
    setAddingClient(false); 
  };

  const handleSaveAdd = () => {
    setAddingClient(false); 
    const fetchClients = async () => {
      try {
        const response = await axios.get(client); 
        setClients(response.data); 
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };
    fetchClients();
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = clients.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(clients.length / recordsPerPage);

  return (
    <>
      <Layout>
        <div style={{ padding:'39px'}}>
            <section className="w-100 p-3">
              <div className='d-flex my-3'>
                <h4>Manage Attorney</h4>
                <button
                  type="button"
                  className="btn btn-outline-success"
                  style={{ marginLeft: 'auto' }}
                  onClick={handleAdd}
                >
                  Add Attorney
                </button>
              </div>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              {addingClient ? (
                <AddClient
                  onCancel={handleCancelAdd}
                  onSave={handleSaveAdd}
                />
              ) : editClientId ? (
                <EditClient
                  clientId={editClientId}
                  onCancel={handleCancelEdit}
                  onSave={handleSaveEdit}
                />
              ) : (
                <div className="datatable">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ padding: '13px',width:'20%' }}>Name</th>
                          <th style={{ padding: '13px' }}>Email</th>
                          <th style={{ padding: '13px' }}>Company Name</th>
                          <th style={{ padding: '13px' }}>Phone</th>
                          <th style={{ padding: '13px' }}>Address</th>
                          <th style={{ padding: '13px',width:'10%' }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRecords.length > 0 ? (
                          currentRecords.map((client) => (
                            <tr className='table_list' key={client.id}>
                              <td>{`${client.firstname} ${client.lastname}`}</td>
                              <td>{client.email}</td>
                              <td>{client.company_name}</td>
                              <td>{client.phone}</td>
                              <td>{client.address.length > 15 ? client.address.slice(0, 15) + '...' : client.address}</td>
                              <td>
                                <div className='btn-group'>
                                  <button
                                    type="button"
                                    className="btn btn-outline-warning"
                                    data-mdb-ripple-init=""
                                    data-mdb-ripple-color="dark"
                                    onClick={() => handleEdit(client.id)}
                                    title='Edit'
                                  >
                                    <i className="fa fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    data-mdb-ripple-init=""
                                    data-mdb-ripple-color="dark"
                                    onClick={() => handleDelete(client.id)}
                                    title='Delete'
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6">No clients available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="datatable-pagination d-flex justify-content-end">
                    <div className="datatable-pagination-buttons">
                      <button
                        className="btn btn-link datatable-pagination-button datatable-pagination-left"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        <i className="fa fa-chevron-left" />
                      </button>
                      <span>{currentPage} of {totalPages}</span>
                      <button
                        className="btn btn-link datatable-pagination-button datatable-pagination-right"
                        disabled={currentPage === totalPages}
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        <i className="fa fa-chevron-right" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </section>
        </div>
      </Layout>
    </>
  );
}

export default Clients;
