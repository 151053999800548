import React from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const Layout = ({ children }) =>  {
  return (
    <div style={{ display: 'flex' }}>
        <Sidebar />
        <div style={{ display: 'block', width: '100%' }}>
            <Navbar />
            <div style={{ padding: '20px',width:'82%', marginLeft:'230px'}}>
              <div  className="row">
                  <div className="col-md-1"></div>
                  <div style={{marginTop:'83px'}} className="col-md-11">
                  <main className='main_section' style={{fontSize:'15px', fontWeight:'400', background:'white',boxShadow:'rgba(100, 100, 111, 0.2) 0px 7px 29px'}}>{children}</main>
                  </div>
              </div>
              
            </div>
        </div>
    </div>
  )
}
export default Layout;
