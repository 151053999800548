import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import Layout from './layout';
import axios from 'axios';
import { profile } from './proxyurls';
import adminImage from '../assets/admin.png';

function Profile() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(profile); 
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Error fetching user data.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleUpdateClick = () => {
    navigate('/profile-edit'); 
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!user) {
    return <p>No user data available.</p>;
  }
  return (
    <Layout>
      <div style={{ padding: '39px' }} className="col-md-4 mx-5 my-5">
          <div className="card card-primary card-outline">
            <div className="card-body box-profile">
              <div className="text-center">
                <img
                  className="image rounded-circle"
                  src={adminImage} 
                  alt="profile_image"
                  style={{ width: '150px', height: '150px', padding: '10px', margin: '0px' }}
                />
              </div>
              <h3 className="profile-username text-center">{user.name}</h3>
              <ul className="list-group list-group-unbordered mb-6">
                <li className="list-group-item"><b>{user.email}</b></li>
                <li className="list-group-item"><b>{user.phone}</b></li>
                <li className="list-group-item"><b>{user.address}</b></li>
                <li className="list-group-item"><b>{user.company_name}</b></li>
              </ul>
              <button onClick={handleUpdateClick} className="btn btn-primary my-3" style={{ width: '100%' }}>
                <b>Update Profile</b>
              </button>
            </div>
          </div>
        </div>
    </Layout>
  );
}

export default Profile;
