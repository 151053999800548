import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { client as CLIENTS_URL, invoicecreate as ADD_INVOICE_URL } from './proxyurls';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import Layout from './layout';

function AddInvoice() {
    const [clients, setClients] = useState([]);
    const [formData, setFormData] = useState({
        project_month: new Date().toISOString().split('T')[0], 
        // project_name: '',
        service_detail: '',
        title: '',
        reference_id: '',
        usd: '',
        status: '',
        payment_status: '',
        assigned_to: '',
        assigned_contact: '',
        assigned_email: '',
        inr: '',
        payment_remarks: '',
        invoice_id: ''
    });
    const [emailOptions, setEmailOptions] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await axios.get(CLIENTS_URL);
                const clientOptions = response.data.map(client => ({
                    value: client.id,
                    label: `${client.firstname} (${client.email}${client.email1 ? `, ${client.email1}` : ''})`,
                    phone: client.phone,
                    email: client.email,
                    email1: client.email1
                }));
                setClients(clientOptions);
            } catch (error) {
                console.error('Error fetching clients:', error);
                setErrorMessage('Error fetching clients.');
            }
        };

        fetchClients();
    }, []);

    const validateForm = () => {
        const errors = {};
        // if (!formData.project_name.trim()) errors.project_name = 'Project Name is required';
        if (!formData.service_detail.trim()) errors.service_detail = 'Service Details are required';
        if (!formData.title.trim()) errors.title = 'Title is required';
        if (!formData.reference_id.trim()) errors.reference_id = 'Reference ID is required';
        if (!formData.usd || isNaN(formData.usd) || Number(formData.usd) <= 0) errors.usd = 'USD Amount must be a positive number';
        // if (!formData.inr || isNaN(formData.inr) || Number(formData.inr) < 0) errors.inr = 'INR Amount must be a non-negative number';
        if (!formData.invoice_id.trim()) errors.invoice_id = 'Project ID is required';
        if (!formData.payment_status) errors.payment_status = 'Payment Status is required';
        
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleAssignedToChange = (selectedOption) => {
        const selectedPerson = selectedOption || {};
        setFormData(prevData => ({
            ...prevData,
            assigned_to: selectedPerson.value || '',
            assigned_contact: selectedPerson.phone || ''
        }));

        const assignedPersonClient = clients.find(client => client.value === selectedPerson.value);
        if (assignedPersonClient) {
            const { email, email1 } = assignedPersonClient;
            setEmailOptions(email1 ? [email, email1] : [email]);
            setFormData(prevData => ({
                ...prevData,
                assigned_email: email || ''
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleDateChange = (date) => {
        setFormData(prevData => ({
            ...prevData,
            project_month: date ? date.toISOString().split('T')[0] : ''
        }));
    };

    const handleEmailChange = (selectedOptions) => {
        const selectedEmails = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setFormData(prevData => ({
            ...prevData,
            assigned_email: selectedEmails.join(', ')
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validateForm()) return; 

        const dataToSend = {
            ...formData,
            inr: formData.inr.trim() === '' ? '0' : formData.inr,

            project_month: formData.project_month 
                ? formData.project_month 
                : new Date().toISOString().split('T')[0],
        };

        try {
            const response = await axios.post(ADD_INVOICE_URL, dataToSend, {
                headers: {
                  'Content-Type': 'application/json',
                },
                withCredentials: true
              });
            console.log('Response:', response.data); 
            
            const invoiceData = response.data.createdInvoice; 
            
            if (!invoiceData || !invoiceData.id) {
                throw new Error('Invoice data not returned');
            }
    
            setSuccessMessage('Invoice created successfully');
            setErrorMessage('');
            setTimeout(() => {
                navigate(`/view-invoice/${invoiceData.id}`, { state: { invoice: invoiceData } });
                setSuccessMessage('');
            }, 1000);
        } catch (error) {
            console.error('Invoice Not Added:', error);
            setErrorMessage('Invoice Not Added');
        }
    };

    const handleCancel = () => {
        navigate('/invoice');
    };

    return (
        <Layout>
            <div style={{ padding: '39px' }}>
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <h3 className='py-3 px-3'>Add Invoice</h3>
                <form onSubmit={handleSubmit} className='py-3 px-3'>
                    <div className="row mb-4">
                        <div className="col-3">
                            <label htmlFor="assigned_to" className="form-label">Attorney</label>
                            <Select
                                id="assigned_to"
                                options={clients}
                                value={clients.find(client => client.value === formData.assigned_to) || null}
                                onChange={handleAssignedToChange}
                                placeholder="Search and select a person"
                                isClearable
                                isSearchable
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="assigned_email" className="form-label">Attorney Email</label>
                            <Select
                                id="assigned_email"
                                options={emailOptions.map(email => ({ value: email, label: email }))}
                                value={emailOptions
                                    .filter(email => formData.assigned_email.split(', ').includes(email))
                                    .map(email => ({ value: email, label: email }))}
                                onChange={handleEmailChange}
                                placeholder="Select emails"
                                isMulti
                                isClearable
                            />
                        </div>
                        <div className="col-3 d-grid">
                            <label htmlFor="project_month" className="form-label">Project Date</label>
                            <DatePicker
                                selected={formData.project_month ? new Date(formData.project_month) : new Date()} // Autofill current date
                                onChange={handleDateChange}
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                                id="project_month"
                                placeholderText="Select Date"
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="invoice_idcc" className="form-label">Invoice ID</label>
                            <input
                                type="text"
                                id="invoice_id"
                                className="form-control"
                                name="invoice_id"
                                value={formData.invoice_id}
                                onChange={handleChange}
                            />
                            {formErrors.invoice_id && <div className="text-danger">{formErrors.invoice_id}</div>}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-6">
                            <label htmlFor="title" className="form-label">Title</label>
                            <textarea
                                id="title"
                                className="form-control"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                            />
                            {formErrors.title && <div className="text-danger">{formErrors.title}</div>}
                        </div>
                        <div className="col-6">
                            <label htmlFor="service_detail" className="form-label">Service Details</label>
                            <textarea
                                id="service_detail"
                                className="form-control"
                                name="service_detail"
                                value={formData.service_detail}
                                onChange={handleChange}
                            />
                            {formErrors.service_detail && <div className="text-danger">{formErrors.service_detail}</div>}
                        </div>
                    </div>
                    {/* <div className="row mb-4">
                        <div className="col-12">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input
                                type="text"
                                id="title"
                                className="form-control"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                            />
                            {formErrors.title && <div className="text-danger">{formErrors.title}</div>}
                        </div>
                    </div> */}
                    <div className="row mb-4">
                        <div className="col-3">
                            <label htmlFor="reference_id" className="form-label">Reference ID</label>
                            <input
                                type="text"
                                id="reference_id"
                                className="form-control"
                                name="reference_id"
                                value={formData.reference_id}
                                onChange={handleChange}
                            />
                            {formErrors.reference_id && <div className="text-danger">{formErrors.reference_id}</div>}
                        </div>
                        <div className="col-3">
                            <label htmlFor="usd" className="form-label">USD Amount</label>
                            <input
                                type="number"
                                id="usd"
                                className="form-control"
                                name="usd"
                                value={formData.usd}
                                onChange={handleChange}
                            />
                            {formErrors.usd && <div className="text-danger">{formErrors.usd}</div>}
                        </div>
                        <div className="col-3">
                            <label htmlFor="inr" className="form-label">INR Amount</label>
                            <input
                                type="number"
                                id="inr"
                                className="form-control"
                                name="inr"
                                value={formData.inr}
                                onChange={handleChange}
                            />
                            {formErrors.inr && <div className="text-danger">{formErrors.inr}</div>}
                        </div>
                        <div className="col-3">
                            <label htmlFor="payment_remarks" className="form-label">Payment Remarks</label>
                            <input
                                type="text"
                                id="payment_remarks"
                                className="form-control"
                                name="payment_remarks"
                                value={formData.payment_remarks}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-3">
                            <label htmlFor="payment_status" className="form-label">Payment Status</label>
                            <select
                                id="payment_status"
                                className="form-control"
                                name="payment_status"
                                value={formData.payment_status}
                                onChange={handleChange}
                            >
                                <option value="">Select Payment Status</option>
                                <option value="1">Paid</option>
                                <option value="0">Pending</option>
                            </select>
                            {formErrors.payment_status && <div className="text-danger">{formErrors.payment_status}</div>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
}

export default AddInvoice;
