import React from 'react';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
import '../Sidebar.css'; 

const Sidebar = () => {

  
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial'}}>
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/home" className="text-decoration-none" style={{ color: 'inherit' }}>
            Admin
          </a>
         </CDBSidebarHeader>
        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
          <NavLink exact to="/home" className={({ isActive }) => (isActive ? 'activeClicked' : '')} activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="home">Home</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/attorneys" className={({ isActive }) => (isActive ? 'activeClicked' : '')} activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="users">Attorney</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/invoice" className={({ isActive }) => (isActive ? 'activeClicked' : '')} activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="file">Invoice</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/freelancers" className={({ isActive }) => (isActive ? 'activeClicked' : '')} activeClassName="activeClicked">
              <CDBSidebarMenuItem icon='laptop-file'> Freelancer</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/profile-edit" className={({ isActive }) => (isActive ? 'activeClicked' : '')} activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user-gear">Profile</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            style={{
              padding: '20px 5px',
            }}
          >
            
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
