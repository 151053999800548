import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthContext'; 
import { logins } from './proxyurls';
import  loginimage  from '../assets/undraw_remotely_2j6y.svg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth(); 
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(logins, { email, password },{
        headers: {
          'Content-Type': 'application/json',
          withCredentials: true
        }
      });
      if (response.data.success) {
        login(response.data.token); 
        navigate('/home');
      } else {
        alert('Invalid credentials');
      }
    } catch (error) {
      console.error('Login failed', error);
      alert('Invalid Email or Password');
    }
  };

  return (
    <>
    <section className="bg-light py-3 py-md-5" style={{height:'100vh'}}>
      <div className="content pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src={loginimage}
                alt="login"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 contents">
              <div className="row justify-content-center mt-4">
                <div className="col-md-8">
                  <div className="mb-4 text-center">
                    <h3>Sign In</h3>
                    <p className="mb-4 fs-6">
                      Welcome To Admin Dashboard
                    </p>
                  </div>
                  <form onSubmit={handleLogin}>
                    <div className="form-group first mb-3">
                      <label htmlFor="email"></label>
                      <input
                          type="email"
                          className="form-control login"
                          name="email"
                          id=""
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                    </div>
                    <div className="form-group last mb-4 mt-2">
                      <label htmlFor="password"></label>
                      <input
                        type="password"
                        className="form-control login"
                        id="password"
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="d-flex mb-5 align-items-center">
                      <label className="control control--checkbox mb-0">
                        <span className="caption">Remember me</span>
                        <input
                          type="checkbox"
                          name="rememberMe"
                          id="rememberMe"
                          defaultChecked
                        />
                        <div className="control__indicator" />
                      </label>
                    </div>
                    <div className="d-grid my-3">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg"
                        style={{color:'#fff', backgroundColor:'#6c63ff', borderColor:'#6c63ff'}}
                      >
                        Log In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </section>
</>

    
  );
};

export default Login;
