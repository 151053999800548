import React, { useState } from 'react';
import axios from 'axios';
import { clientadd } from './proxyurls'; 
import { useNavigate } from 'react-router-dom';
import Layout from './layout';

function AddClient({ onCancel, onSave }) {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    email1: '',
    address: '',
    // country: '',
    // state: '',
    // city: '',
    // zipCode: '',
    company_name: ''
  });
  const [showAdditionalEmail, setShowAdditionalEmail] = useState(false); 
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); 
  const [formErrors, setFormErrors] = useState({}); 
  const navigate = useNavigate(); 

  const validateForm = () => {
    const errors = {};
    if (!formData.firstname.trim()) errors.firstname = 'First Name is required';
    if (!formData.lastname.trim()) errors.lastname = 'Last Name is required';
    if (!formData.email.trim()) errors.email = 'Email is required';
    if (!formData.phone) errors.phone = 'Phone number is required';
    if (formData.email1 && !/^\S+@\S+\.\S+$/.test(formData.email1)) errors.email1 = 'Additional Email is invalid';
    if (!formData.address.trim()) errors.address = 'Address is required';
    if (!formData.company_name.trim()) errors.company_name = 'Company Name is required';
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    
    if (!validateForm()) return; 

    try {
      await axios.post(clientadd, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      });
      setSuccessMessage('Attorney created successfully');
      setErrorMessage(''); 
      localStorage.setItem('successMessage', 'Attorney created successfully');
      setTimeout(() => {
        navigate('/attorneys');
        if (typeof onSave === 'function') {
          onSave();
        }
        setSuccessMessage(''); 
      }, 1000); 
    } catch (error) {
      console.error('Error adding client:', error);
      setErrorMessage(error.response?.data?.error || 'An error occurred');
    }
  };

  const handleCancel = () => {
    navigate('/attorneys');
  };

  const toggleAdditionalEmail = () => {
    setShowAdditionalEmail(!showAdditionalEmail);
  };

  return (
    <Layout>
      <div style={{ padding: '39px' }}>
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
          <div className='d-flex mb-5'>
            <h3>Add Attorney</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-4">
                <label htmlFor="firstname" className="form-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                />
                {formErrors.firstname && <div className="text-danger">{formErrors.firstname}</div>}
              </div>
              <div className="col-4">
                <label htmlFor="lastname" className="form-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                />
                {formErrors.lastname && <div className="text-danger">{formErrors.lastname}</div>}
              </div>
              <div className="col-4">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  inputMode="numeric"
                />
                {formErrors.phone && <div className="text-danger">{formErrors.phone}</div>}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {formErrors.email && <div className="text-danger">{formErrors.email}</div>}
              </div>
              <div className="col-1 mt-4">
                <button
                  type="button"
                  onClick={toggleAdditionalEmail}
                  className={`btn ${showAdditionalEmail ? 'btn-danger' : 'btn-success'}`}
                >
                  {showAdditionalEmail ? <i className="fa-solid fa-circle-minus"></i> : <i className="fa-solid fa-circle-plus"></i>} 
                </button>
              </div>
              {showAdditionalEmail && (
                <div className="col-2 pt-0">
                  <label htmlFor="email1" className="form-label">Additional Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email1"
                    name="email1"
                    value={formData.email1}
                    onChange={handleChange}
                  />
                  {formErrors.email1 && <div className="text-danger">{formErrors.email1}</div>}
                </div>
              )}
              <div className="col-6">
                <label htmlFor="company_name" className="form-label">Company Name</label>
                <textarea
                  className="form-control"
                  id="company_name"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                ></textarea>
                {formErrors.company_name && <div className="text-danger">{formErrors.company_name}</div>}
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-12">
                <label htmlFor="address" className="form-label">Address</label>
                <textarea
                  className="form-control"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                ></textarea>
                {formErrors.address && <div className="text-danger">{formErrors.address}</div>}
              </div>
            </div>
            <button type="submit" className="btn btn-primary mt-3">Submit</button>
            <button type="button" onClick={handleCancel} className="btn btn-warning mt-3 mx-3">Cancel</button>
          </form>
        </div>
    </Layout>
  );
}

export default AddClient;
