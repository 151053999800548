import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { clientedit, clienteupdate } from './proxyurls';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from './layout';

const EditClient = ({ onCancel, onSave }) => {
  const { id: clientId } = useParams(); 
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    email1: '',
    address: '',
    // country: '',
    // state: '',
    // city: '',
    // zipCode: '',
    company_name: ''
  });
  const [showAdditionalEmail, setShowAdditionalEmail] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get(clientedit(clientId));
        const data = response.data;
        setFormData({
          firstname: data.firstname || '',
          lastname: data.lastname || '',
          email: data.email || '',
          phone: data.phone || '',
          email1: data.email1 || '',
          address: data.address || '',
          // state: data.state || '',
          // city: data.city || '',
          // zipCode: data.zipCode || '',
          company_name: data.company_name || ''
        });
        setShowAdditionalEmail(!!data.email1);
      } catch (error) {
        console.error('Error fetching client data:', error);
        setErrorMessage('Failed to fetch client data.');
      } finally {
        setLoading(false);
      }
    };

    fetchClientData();
  }, [clientId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(clienteupdate(clientId), formData,{
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      }); 
      setSuccessMessage('Attorney updated successfully');
      setErrorMessage('');
      localStorage.setItem('successMessage', 'Attorney updated successfully');
      setTimeout(() => {
        navigate('/attorneys');
        if (typeof onSave === 'function') {
          onSave();
        }
        setSuccessMessage('');
      }, 1000);
    } catch (error) {
      console.error('Error updating client data:', error);
      setErrorMessage('Failed to update client. Please try again.');
    }
  };

  const handleCancel = () => {
    navigate('/attorneys');
  };

  const toggleAdditionalEmail = () => {
    setShowAdditionalEmail(!showAdditionalEmail);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <div style={{ padding: '39px' }}>
          {successMessage && <div className="alert alert-success">{successMessage}</div>}
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
          <div className='d-flex mb-5'>
            <h3>Edit Attorney</h3>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-4">
                <label htmlFor="firstname" className="form-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-4">
                <label htmlFor="lastname" className="form-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-4">
                <label htmlFor="phone" className="form-label">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  pattern="\d*"
                  inputMode="numeric"
                  required
                />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-1 mt-4">
                <button
                  type="button"
                  onClick={toggleAdditionalEmail}
                  className={`btn ${showAdditionalEmail ? 'btn-danger' : 'btn-success'}`}
                >
                  {showAdditionalEmail ? <i className="fa-solid fa-circle-minus"></i> : <i className="fa-solid fa-circle-plus"></i>}
                </button>
              </div>
              {showAdditionalEmail && (
                <div className="col-2 pt-0">
                  <label htmlFor="email1" className="form-label">Additional Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email1"
                    name="email1"
                    value={formData.email1}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className="col-6">
                <label htmlFor="company_name" className="form-label">Company Name</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="company_name"
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-12">
                <label htmlFor="address" className="form-label">Address</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              {/* <div className="col-3">
                <label htmlFor="state" className="form-label">State</label>
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-3">
                <label htmlFor="city" className="form-label">City</label>
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-3">
                <label htmlFor="zipCode" className="form-label">Zip Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="zipCode"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  required
                />
              </div> */}
            </div>

            <button type="submit" className="btn btn-primary mt-3">Submit</button>
            <button type="button" onClick={handleCancel} className="btn btn-warning mt-3 mx-3">Cancel</button>
          </form>
        </div>
    </Layout>
  );
};

export default EditClient;
