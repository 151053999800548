import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { editprofile, updateprofile } from './proxyurls'; 
import Layout from './layout';

function ProfileEdit() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    company_name: '',
    password: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = 1;
        const response = await axios.get(editprofile(userId)); 
        setFormData({
          name: response.data.name,
          email: response.data.email,
          phone: response.data.phone,
          address: response.data.address,
          company_name: response.data.company_name,
          password: ''
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Error fetching user data.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userId = 1; 
      await axios.put(updateprofile(userId), formData,{
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      }); 
      navigate('/profile'); 
    } catch (error) {
      console.error('Error updating user data:', error);
      setError('Error updating user data.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Layout>
      <div style={{ padding: '39px' }} className="col-md-10 mx-5 my-4">
        <h4 className="profile-username py-3">Edit Profile</h4>
        <form onSubmit={handleSubmit}>
          <div className="form-group input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-user" style={{ fontSize: '20px' }} />
              </span>
            </div>
            <input
              className="form-control p-1 mb-4"
              placeholder="Name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-envelope" style={{ fontSize: '20px' }} />
              </span>
            </div>
            <input
              className="form-control p-1 mb-4"
              placeholder="Email address"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-lock" style={{ fontSize: '20px' }} />
              </span>
            </div>
            <input
              className="form-control p-1 mb-4"
              placeholder="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className="form-group input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-phone" style={{ fontSize: '20px' }} />
              </span>
            </div>
            <input
              className="form-control p-1 mb-4"
              placeholder="Phone number"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              pattern="\d*"
              inputMode="numeric"
              required
            />
          </div>
          <div className="form-group input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-address-card" style={{ fontSize: '20px' }} />
              </span>
            </div>
            <input
              className="form-control p-1 mb-4"
              placeholder="Address"
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-building" style={{ fontSize: '20px' }} />
              </span>
            </div>
            <input
              className="form-control p-1 mb-4"
              placeholder="Company Name"
              type="text"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary btn-block">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </Layout>
  );
}

export default ProfileEdit;
