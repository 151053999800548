import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { freelanceredit, freelancerupdate } from './proxyurls';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import Layout from './layout';

const statusOptions = [
    { value: 1, label: 'Done' },
    { value: 0, label: 'Pending' }
];

function EditFreelancer() {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        inr: '',
        payment_status: '',
        payment_remarks: '',
        project_id: '',
        projectname: '',
        services_details: '',
        title: '',
        project_status: '',
        project_startdate: new Date(), 
        project_enddate: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { id } = useParams(); 

    useEffect(() => {
        const fetchFreelancer = async () => {
            try {
                const response = await axios.get(freelanceredit(id));
                setFormData(response.data);
            } catch (error) {
                console.error('Error fetching freelancer details:', error);
                setErrorMessage('Error fetching freelancer details');
            }
        };

        fetchFreelancer();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption, fieldName) => {
        setFormData(prevData => ({
            ...prevData,
            [fieldName]: selectedOption ? selectedOption.value : ''
        }));
    };

    const handleDateChange = (date, fieldName) => {
        setFormData(prevData => ({
            ...prevData,
            [fieldName]: date ? date.toISOString().split('T')[0] : ''
        }));
    };
    const updatedFormData = {
        ...formData,
        project_startdate: formData.project_startdate 
            ? formData.project_startdate 
            : new Date().toISOString().split('T')[0],  
    
        project_enddate: formData.project_enddate 
            ? formData.project_enddate 
            : new Date().toISOString().split('T')[0]   
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(freelancerupdate(id), updatedFormData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            });
            setSuccessMessage('Freelancer updated successfully');
            setErrorMessage('');
            localStorage.setItem('successMessage', 'Freelancer updated successfully');
            setTimeout(() => {
                navigate('/freelancers');
                setSuccessMessage('');
            }, 1000);
        } catch (error) {
            console.error('Error updating freelancer:', error);
            setErrorMessage(error.response?.data?.error || 'An error occurred');
        }
    };

    const handleCancel = () => {
        navigate('/freelancers');
    };

    return (
        <Layout>
            <div style={{ padding: '39px' }}>
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <h3 className='py-3 px-3'>Edit Freelancer</h3>
                <form onSubmit={handleSubmit} className='py-3 px-3'>
                    <div className="row mb-4">
                        <div className="col-3">
                            <label htmlFor="firstname" className="form-label">First Name</label>
                            <input
                                type="text"
                                id="firstname"
                                className="form-control"
                                name="firstname"
                                value={formData.firstname}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="lastname" className="form-label">Last Name</label>
                            <input
                                type="text"
                                id="lastname"
                                className="form-control"
                                name="lastname"
                                value={formData.lastname}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                id="email"
                                className="form-control"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-3 d-grid">
                            <label htmlFor="phone" className="form-label">Phone</label>
                            <input
                                type="text"
                                id="phone"
                                className="form-control"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-6">
                            <label htmlFor="projectname" className="form-label">Project Name</label>
                            <textarea
                                type="text"
                                id="projectname"
                                className="form-control"
                                name="projectname"
                                value={formData.projectname}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-6">
                            <label htmlFor="services_details" className="form-label">Service Details</label>
                            <textarea
                                id="services_details"
                                className="form-control"
                                name="services_details"
                                value={formData.services_details}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <label htmlFor="title" className="form-label">Title</label>
                            <input
                                type="text"
                                id="title"
                                className="form-control"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-3">
                            <label htmlFor="project_startdate" className="form-label">Project Start Date</label>
                            <DatePicker
                                id="project_startdate"
                                className="form-control"
                                // selected={new Date(formData.project_startdate)}
                                selected={formData.project_startdate ? new Date(formData.project_startdate) : new Date()}
                                onChange={date => handleDateChange(date, 'project_startdate')}
                                dateFormat="yyyy-MM-dd"
                                required
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="project_enddate" className="form-label">Project End Date</label>
                            <DatePicker
                                id="project_enddate"
                                className="form-control"
                                selected={formData.project_enddate ? new Date(formData.project_enddate) : new Date()}
                                onChange={date => handleDateChange(date, 'project_enddate')}
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="inr" className="form-label">INR</label>
                            <input
                                type="number"
                                id="inr"
                                className="form-control"
                                name="inr"
                                value={formData.inr}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="payment_status" className="form-label">Payment Status</label>
                            <Select
                                id="payment_status"
                                name="payment_status"
                                options={statusOptions}
                                value={statusOptions.find(option => option.value === formData.payment_status)}
                                onChange={option => handleSelectChange(option, 'payment_status')}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-3">
                            <label htmlFor="payment_remarks" className="form-label">Payment Remarks</label>
                            <input
                                type="text"
                                id="payment_remarks"
                                className="form-control"
                                name="payment_remarks"
                                value={formData.payment_remarks}
                                onChange={handleChange}
                                
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="project_status" className="form-label">Project Status</label>
                            <Select
                                id="project_status"
                                name="project_status"
                                options={statusOptions}
                                value={statusOptions.find(option => option.value === formData.project_status)}
                                onChange={option => handleSelectChange(option, 'project_status')}
                                required
                            />
                        </div>
                        <div className="col-3">                                 
                            <label htmlFor="project_id" className="form-label">Project Id</label>
                            <input
                                type="text"
                                id="project_id"
                                className="form-control"
                                name="project_id"
                                value={formData.project_id}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">Save</button>
                            <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
}

export default EditFreelancer;
