import React, { createContext, useContext, useState } from 'react';

const NotificationContext = createContext();

export function NotificationProvider({ children }) {
  const [message, setMessage] = useState('');

  const notify = (msg) => setMessage(msg);
  const clearMessage = () => setMessage('');

  return (
    <NotificationContext.Provider value={{ message, notify, clearMessage }}>
      {children}
      {message && <div className="alert alert-success">{message}</div>}
    </NotificationContext.Provider>
  );
}

export const useNotification = () => useContext(NotificationContext);
