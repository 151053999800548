import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from './layout';
import { viewinvoice, invoiceUpdateStatus } from './proxyurls';
import vls from '../assets/logovls.png';

function ViewInvoice() {
    const { id } = useParams();
    const [invoice, setInvoice] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchInvoice = async () => {
            try {
                const response = await axios.get(viewinvoice(id));
                setInvoice(response.data);
            } catch (error) {
                console.error('Error fetching invoice:', error);
                setErrorMessage('Error fetching invoice.');
            }
        };

        fetchInvoice();
    }, [id]);

    const handleSendOrResend = async () => {
        setLoading(true);
        try {
            await axios.patch(invoiceUpdateStatus(id), { status: 1 },{
                headers: {
                  'Content-Type': 'application/json',
                 
                },
                withCredentials: true
              });
            // navigate('/invoice');
            setSuccessMessage('Invoice Sent Successfully');
            setErrorMessage('');
            localStorage.setItem('successMessage', 'Invoice Sent Successfully');
            setTimeout(() => {
                navigate('/invoice');
                setSuccessMessage('');
            }, 1000);
        } catch (error) {
            console.error('Invoice Not Sent:', error);
            setErrorMessage('Invoice Not Sent.');
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        navigate('/invoice');
    };

    if (!invoice) return null;

    return (
        <Layout>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <div
                style={{
                    maxWidth: 800,
                    height: "100%",
                    margin: "20px auto",
                    backgroundColor: "#FFFFFF",
                    padding: 70,
                    borderRadius: 10,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    position: "relative",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        position: "relative",
                        width: "21ch",
                        height: 0,
                        borderTop: "100px solid transparent",
                        borderBottom: "100px solid transparent",
                        borderLeft: "100px solid #852E14",
                        top: "-17ch",
                        left: "-10ch",
                        borderLeftWidth: "62rem",
                        transform: "rotate(-4deg)"
                    }}
                >
                </div>
                <div className="wrap-sec" style={{ position: "relative", bottom: "11ch" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderRadius: "0 0 20px 20px",
                            backgroundColor: "#FFFFFF",
                            position: "relative",
                            zIndex: 1,
                            margin: 0
                        }}
                    >
                        <div style={{ flex: 1 }}>
                            <h1 style={{ margin: 0, fontSize: 18 }}>
                                <span style={{ fontWeight: 'bold', fontSize: 18 }}> Virtue Legal Services </span>   30 N GOULD
                            </h1>
                            <p style={{ margin: "5px 0", fontSize: 18, color:"#151515" }}>
                                ST STE 22405, SHERIDAN, WY 82801
                            </p>
                        </div>
                        <div>
                            <img
                                src={vls}
                                alt="Logo"
                                style={{ width: 184, position: "relative", bottom: "12ch" }}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: 0 }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: 20
                            }}
                        >
                            <div style={{ width: "49%" }}>
                                <div style={{ marginBottom: 10, fontWeight: 600 }}>
                                    <strong>Reference#:</strong> {invoice.reference_id}
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <strong>Name:</strong> {invoice.assigned.firstname} {invoice.assigned.lastname}
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <strong>Firm Name:</strong> {invoice.assigned.company_name || "N/A"}
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <strong>Address:</strong> {invoice.assigned.address || "N/A"}
                                </div>
                            </div>
                            <div style={{ width: "48%", textAlign: "end" }}>
                                <div style={{ marginBottom: 10 }}>
                                    <strong>INVOICE #:</strong> {invoice.invoice_id}
                                </div>
                                <div>
                                    <strong>INVOICE DATE:</strong> {invoice.current_date || new Date().toLocaleDateString('en-US', {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: 'numeric',
                                    })}
                                </div>
                            </div>
                        </div>
                        <table
                            style={{
                                width: "100%",
                                borderCollapse: "collapse",
                                marginBottom: 20,
                                border: "2px solid black"
                            }}
                        >
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            padding: 10,
                                            textAlign: "center",
                                            border: "1px solid black",
                                            backgroundColor: "#852E14",
                                            color: "white"
                                        }}
                                    >
                                        Service Details
                                    </th>
                                    <th
                                        style={{
                                            padding: 10,
                                            textAlign: "center",
                                            border: "1px solid black",
                                            backgroundColor: "#852E14",
                                            color: "white"
                                        }}
                                    >
                                        Price
                                    </th>
                                    <th
                                        style={{
                                            padding: 10,
                                            textAlign: "center",
                                            border: "1px solid black",
                                            backgroundColor: "#852E14",
                                            color: "white"
                                        }}
                                    >
                                        Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ backgroundColor: "#FFFFFF" }}>
                                    <td
                                        style={{
                                            padding: 10,
                                            textAlign: "left",
                                            border: "1px solid black",
                                            maxHeight: 100,
                                            overflow: "hidden",
                                            paddingBottom: 150,
                                            fontWeight: 700,
                                            width: "71%%"

                                        }}
                                    >
                                        {invoice.service_detail || "N/A"}
                                    </td>
                                    <td
                                        style={{
                                            padding: 10,
                                            textAlign: "center",
                                            border: "1px solid black",
                                            paddingBottom: 140
                                        }}
                                    >
                                        ${invoice.usd || "N/A"}
                                    </td>
                                    <td
                                        style={{
                                            padding: 10,
                                            textAlign: "center",
                                            border: "1px solid black",
                                            paddingBottom: 140,
                                        }}
                                    >
                                        ${invoice.usd || "N/A"}
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: "lightgrey" }}>
                                    <td
                                        style={{
                                            padding: 20,
                                            textAlign: "center",
                                            border: "1px solid black",
                                            fontWeight: "bold",
                                            color: "gray"
                                        }}
                                    >
                                        Total
                                    </td>
                                    <td
                                        style={{
                                            padding: 20,
                                            textAlign: "center",
                                            border: "1px solid black",
                                            fontWeight: "bold",
                                            color: "gray"
                                        }}
                                    >
                                        ${invoice.usd || "N/A"}
                                    </td>
                                    <td
                                        style={{
                                            padding: 20,
                                            textAlign: "center",
                                            border: "1px solid black",
                                            fontWeight: "bold",
                                            color: "gray"
                                        }}
                                    >
                                        ${invoice.usd || "N/A"}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ marginTop: 20, paddingTop: 10 }}>
                            <p style={{ margin: 0, marginBottom: 10, color:"#151515" }}>
                                <strong>ACCOUNT DETAILS:</strong>
                            </p>
                            <p style={{ margin: 0, marginBottom: 25, marginLeft: 41, color:"#151515" }}>
                                ACH deposits:
                            </p>
                            <p style={{ margin: 0, marginBottom: 10, marginLeft: 61, color:"#151515" }}>
                                Checking Account Number: 777205375
                            </p>
                            <p style={{ margin: 0, marginBottom: 10, marginLeft: 61, color:"#151515" }}>
                                Routing Number: 061092387
                            </p>
                            <p style={{ margin: 0, marginBottom: 10, marginLeft: 61, color:"#151515" }}>
                                JP Morgan Chase Bank
                            </p>
                        </div>
                        {/* <div style={{ marginTop: 20, paddingTop: 10 }}>
                            <p style={{ margin: 0, marginBottom: 10, color:"#151515" }}>
                                <strong>SIGNATURE:</strong>
                            </p>
                            <p style={{ margin: 0, marginBottom: 10 }}>_________________________</p>
                        </div> */}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSendOrResend}
                        disabled={loading} 
                        style={{border:'#852e14', background:'#852e14'}}
                    >
                        {loading
                            ? 'Sending...'
                            : invoice.status === 1
                            ? 'Resend Invoice'
                            : 'Send Invoice'}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleCancel}
                        disabled={loading} 
                    >
                        Cancel
                    </button>
                    {loading && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'rgba(255, 255, 255, 0.8)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 1000,
                                borderRadius: 8,
                            }}
                        >
                            <div
                                style={{
                                    border: '8px solid rgba(0, 0, 0, 0.1)', // Light grey border
                                    borderTop: '8px solid #852e14', // Darker border for spinning effect
                                    borderRadius: '50%',
                                    width: '50px',
                                    height: '50px',
                                    animation: 'spin 1s linear infinite',
                                }}
                            />
                            <style>
                                {`
                                    @keyframes spin {
                                        0% { transform: rotate(0deg); }
                                        100% { transform: rotate(360deg); }
                                    }
                                `}
                            </style>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default ViewInvoice;
