// local
// export const host = "http://localhost:3000";

// live
export const host = "https://vlsinvoicing.virtuelegalservices.com";



// auth
export const logins = host+'/login';
export const logouts = host+'/logout';
export const authcheck = host+'/check';

// attorney
export const client = host+'/clients';
export const clientdelete = (id) => `${host}/client-delete/${id}`;
export const clientedit = (id) => `${host}/client-edit/${id}`;
export const clienteupdate = (id) => `${host}/client-update/${id}`;
export const clientadd =  `${host}/client-create`;


// invoice
export const invoice =  `${host}/invoices`;
export const invoicecreate =  `${host}/invoice-create`;
export const invoiceedit = (id) => `${host}/invoice-edit/${id}`;
export const invoiceupdate = (id) => `${host}/invoice-update/${id}`;
export const viewinvoice = (id) => `${host}/invoice/${id}`;
export const invoiceUpdateStatus = (id) => `${host}/update-status/${id}`;

// admin
export const profile = `${host}/profile`;
export const editprofile = (id) => `${host}/edit-profile/${id}`;
export const updateprofile = (id) => `${host}/update-profile/${id}`;

// freelancer
export const freelancers = host+'/freelancers';
export const freelancerdelete = (id) => `${host}/freelancer-delete/${id}`;
export const freelanceredit = (id) => `${host}/freelancer-edit/${id}`;  
export const freelancerupdate = (id) => `${host}/freelancer-update/${id}`;
export const freelanceradd =  `${host}/freelancer-create`;
export const freelancerdetails = (id) => `${host}/freelancer/${id}`;

//counts

export const counts = `${host}/counts`;