import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { freelancers as FREELANCERS_URL, freelancerdelete as FREELANCER_DELETE_URL } from './proxyurls'; 
import EditFreelancer from './EditFreelancer'; 
import AddFreelancer from './AddFreelancer'; 
import { useNavigate } from 'react-router-dom';
import Layout from './layout';

function Freelancers() {
  const [freelancers, setFreelancers] = useState([]);
  const [editFreelancerId, setEditFreelancerId] = useState(null); 
  const [addingFreelancer, setAddingFreelancer] = useState(false); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [successMessage, setSuccessMessage] = useState(''); 
  const recordsPerPage = 12; 
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchFreelancers = async () => {
      try {
        const response = await axios.get(FREELANCERS_URL); 
        setFreelancers(response.data); 
      } catch (error) {
        console.error('Error fetching freelancer data:', error);
      }
    };

    fetchFreelancers();
  }, []);

  useEffect(() => {
    const successMessage = localStorage.getItem('successMessage');
    if (successMessage) {
      setSuccessMessage(successMessage);
      localStorage.removeItem('successMessage');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    }
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this freelancer?');
    if (!confirmDelete) return;

    try {
      await axios.delete(FREELANCER_DELETE_URL(id));
      setFreelancers(freelancers.filter((freelancer) => freelancer.id !== id));
      setSuccessMessage('Freelancer deleted successfully');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error deleting freelancer:', error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-freelancer/${id}`); 
  };

  const handleCancelEdit = () => {
    setEditFreelancerId(null); 
  };

  const handleSaveEdit = () => {
    setEditFreelancerId(null); 
    const fetchFreelancers = async () => {
      try {
        const response = await axios.get(FREELANCERS_URL); 
        setFreelancers(response.data); 
      } catch (error) {
        console.error('Error fetching freelancer data:', error);
      }
    };
    fetchFreelancers();
  };

  const handleAdd = () => {
    navigate('/add-freelancer'); 
  };
  const handleViewfreelancer = (id) => {
    navigate(`/view-freelancer/${id}`); 
  };

  const handleCancelAdd = () => {
    setAddingFreelancer(false); 
  };

  const handleSaveAdd = () => {
    setAddingFreelancer(false); 
    const fetchFreelancers = async () => {
      try {
        const response = await axios.get(FREELANCERS_URL); 
        setFreelancers(response.data); 
      } catch (error) {
        console.error('Error fetching freelancer data:', error);
      }
    };
    fetchFreelancers();
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = freelancers.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(freelancers.length / recordsPerPage);

  return (
    <>
      <Layout>
        <div style={{ padding: '39px' }}>
            <section className="w-100 p-3">
              <div className='d-flex my-3'>
                <h4>Manage Freelancers</h4>
                <button
                  type="button"
                  className="btn btn-outline-success"
                  style={{ marginLeft: 'auto' }}
                  onClick={handleAdd}
                >
                  Add Freelancer
                </button>
              </div>
              {successMessage && (
                <div className="alert alert-success" role="alert">
                  {successMessage}
                </div>
              )}
              {addingFreelancer ? (
                <AddFreelancer
                  onCancel={handleCancelAdd}
                  onSave={handleSaveAdd}
                />
              ) : editFreelancerId ? (
                <EditFreelancer
                  freelancerId={editFreelancerId}
                  onCancel={handleCancelEdit}
                  onSave={handleSaveEdit}
                />
              ) : (
                <div className="datatable">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ padding: '13px', width:'20%' }}>Name</th>
                          <th style={{ padding: '13px' }}>Email</th>
                          <th style={{ padding: '13px' }}>Phone</th>
                          <th style={{ padding: '13px' }}>Project Name</th>
                          <th style={{ padding: '13px' }}>Inr</th>
                          <th style={{ padding: '13px' }}>Payment Status</th>
                          <th style={{ padding: '13px' }}>Project Status</th>
                          <th style={{ padding: '13px', width:'13%' }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRecords.length > 0 ? (
                          currentRecords.map((freelancer) => (
                            <tr className='table_list' key={freelancer.id}>
                              <td>{`${freelancer.firstname} ${freelancer.lastname}`}</td>
                              <td>{freelancer.email}</td>
                              <td>{freelancer.phone}</td>
                              <td>{freelancer.projectname}</td>
                              <td>{freelancer.inr}</td>
                              <td>
                                {freelancer.payment_status === 0 ? 'Pending' : 'Done'}
                              </td>
                              <td>
                                {freelancer.project_status === 0 ? 'Pending' : 'Done'}
                              </td>
                              <td>
                                <div className='btn-group'>
                                  <button
                                    type="button"
                                    className="btn btn-outline-info"
                                    onClick={() => handleViewfreelancer(freelancer.id)}
                                    title='View'
                                  >
                                    <i className="fa fa-eye"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-warning"
                                    data-mdb-ripple-init=""
                                    data-mdb-ripple-color="dark"
                                    onClick={() => handleEdit(freelancer.id)}
                                    title='Edit'
                                  >
                                    <i className="fa fa-edit"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    data-mdb-ripple-init=""
                                    data-mdb-ripple-color="dark"
                                    onClick={() => handleDelete(freelancer.id)}
                                    title='Delete'
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">No freelancers available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="datatable-pagination d-flex justify-content-end">
                    <div className="datatable-pagination-buttons">
                      <button
                        className="btn btn-link datatable-pagination-button datatable-pagination-left"
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        <i className="fa fa-chevron-left" />
                      </button>
                      <span>{currentPage} of {totalPages}</span>
                      <button
                        className="btn btn-link datatable-pagination-button datatable-pagination-right"
                        disabled={currentPage === totalPages}
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        <i className="fa fa-chevron-right" />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </section>
        </div>
      </Layout>
    </>
  );
}

export default Freelancers;
