// import React, { useEffect, useState } from 'react';
// import Layout from './layout';
// import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react';
// import { CChartBar, CChartLine } from '@coreui/react-chartjs';
// import axios from 'axios'; 
// import {counts} from './proxyurls'; 

// function Home() {
//   const [data, setData] = useState({
//     clients: 0,
//     freelancers: 0,
//     invoices: {
//       status0: 0,
//       status1: 0
//     }
//   });
//   const random = () => Math.round(Math.random() * 100);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(counts); 
//         setData(response.data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <>
//       <Layout>
//         <div style={{ padding: '20px' }}>
//           <div className="row">
//             <div className="col-md-4 col-xl-3">
//               <div className="card bg-c-blue order-card">
//                 <div className="card-block">
//                   <h6 className="m-b-20">Total Attorneys</h6>
//                   <h2 className="text-right mt-4">
//                     <i className="fa fa-users f-left" />
//                     <span className='discription_card'>{data.clients}</span>
//                   </h2>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 col-xl-3">
//               <div className="card bg-c-green order-card">
//                 <div className="card-block">
//                   <h6 className="m-b-20">Total Freelancers</h6>
//                   <h2 className="text-right mt-4">
//                     <i className="fa fa-users f-left" />
//                     <span className='discription_card'>{data.freelancers}</span>
//                   </h2>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 col-xl-3">
//               <div className="card bg-c-pink order-card">
//                 <div className="card-block">
//                   <h6 className="m-b-20">Total Send Invoices</h6>
//                   <h2 className="text-right mt-4">
//                     <i className="fa fa-file f-left" />
//                     <span className='discription_card'>{data.invoices.status1}</span>
//                   </h2>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4 col-xl-3">
//               <div className="card bg-c-yellow order-card">
//                 <div className="card-block">
//                   <h6 className="m-b-20">Pending Invoices</h6>
//                   <h2 className="text-right mt-4">
//                     <i className="fa fa-refresh f-left" />
//                     <span className='discription_card'>{data.invoices.status0}</span>
//                   </h2>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <CRow>
//             <CCol xs={6}>
//               <CCard className="mb-4">
//                 <CCardHeader>Bar Chart</CCardHeader>
//                 <CCardBody>
//                   <CChartBar
//                     data={{
//                       labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//                       datasets: [
//                         {
//                           label: 'GitHub Commits',
//                           backgroundColor: '#f87979',
//                           data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
//                         },
//                       ],
//                     }}
//                     labels="months"
//                   />
//                 </CCardBody>
//               </CCard>
//             </CCol>
//             <CCol xs={6}>
//               <CCard className="mb-4">
//                 <CCardHeader>Line Chart</CCardHeader>
//                 <CCardBody>
//                   <CChartLine
//                     data={{
//                       labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//                       datasets: [
//                         {
//                           label: 'My First dataset',
//                           backgroundColor: 'rgba(220, 220, 220, 0.2)',
//                           borderColor: 'rgba(220, 220, 220, 1)',
//                           pointBackgroundColor: 'rgba(220, 220, 220, 1)',
//                           pointBorderColor: '#fff',
//                           data: [random(), random(), random(), random(), random(), random(), random()],
//                         },
//                         {
//                           label: 'My Second dataset',
//                           backgroundColor: 'rgba(151, 187, 205, 0.2)',
//                           borderColor: 'rgba(151, 187, 205, 1)',
//                           pointBackgroundColor: 'rgba(151, 187, 205, 1)',
//                           pointBorderColor: '#fff',
//                           data: [random(), random(), random(), random(), random(), random(), random()],
//                         },
//                       ],
//                     }}
//                   />
//                 </CCardBody>
//               </CCard>
//             </CCol>
//           </CRow>
//         </div>
//       </Layout>
//     </>
//   );
// }

// export default Home;

import React, { useEffect, useState } from 'react';
import Layout from './layout';
import { CCard, CCardBody, CCol, CCardHeader, CRow } from '@coreui/react';
import { CChartBar, CChartLine } from '@coreui/react-chartjs';
import axios from 'axios'; 
import { counts } from './proxyurls'; 

function Home() {
  const [data, setData] = useState({
    clients: 0,
    freelancers: 0,
    status0: 0,
    status1: 0,
    invoicesByMonth: [],
    incomeByMonth: []
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(counts);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Prepare data for charts
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const invoiceChartLabels = data.invoicesByMonth.map(item => `${months[item.month - 1]} ${item.year}`);
  const invoiceChartData = data.invoicesByMonth.map(item => item.total_invoices);

  const incomeChartData = data.incomeByMonth.map(item => item.total_income);

  return (
    <>
      <Layout>
        <div style={{ padding: '39px' }}>
          <div className="row">
            <div className="col-md-4 col-xl-3">
              <div className="card bg-c-blue order-card">
                <div className="card-block">
                  <h6 className="m-b-20">Total Attorneys</h6>
                  <h2 className="text-right mt-4">
                    <i className="fa fa-users f-left" />
                    <span className='discription_card'>{data.clients}</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-3">
              <div className="card bg-c-green order-card">
                <div className="card-block">
                  <h6 className="m-b-20">Total Freelancers</h6>
                  <h2 className="text-right mt-4">
                    <i className="fa fa-users f-left" />
                    <span className='discription_card'>{data.freelancers}</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-3">
              <div className="card bg-c-pink order-card">
                <div className="card-block">
                  <h6 className="m-b-20">Total Sent Invoices</h6>
                  <h2 className="text-right mt-4">
                    <i className="fa fa-file f-left" />
                    <span className='discription_card'>{data.status1}</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xl-3">
              <div className="card bg-c-yellow order-card">
                <div className="card-block">
                  <h6 className="m-b-20">Pending Invoices</h6>
                  <h2 className="text-right mt-4">
                    <i className="fa fa-refresh f-left" />
                    <span className='discription_card'>{data.status0}</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <CRow>
            <CCol xs={6}>
              <CCard className="mb-4">
                <CCardHeader>Invoices</CCardHeader>
                <CCardBody>
                  <CChartBar
                    data={{
                      labels: invoiceChartLabels,
                      datasets: [
                        {
                          label: 'Total Invoices',
                          backgroundColor: '#f87979',
                          data: invoiceChartData,
                        },
                      ],
                    }}
                  />
                </CCardBody>
              </CCard>
            </CCol>
            <CCol xs={6}>
              <CCard className="mb-4">
                <CCardHeader>Invocies Income</CCardHeader>
                <CCardBody>
                  <CChartLine
                    data={{
                      labels: invoiceChartLabels,
                      datasets: [
                        {
                          label: 'Total Income (USD)',
                          backgroundColor: 'rgba(151, 187, 205, 0.2)',
                          borderColor: 'rgba(151, 187, 205, 1)',
                          pointBackgroundColor: 'rgba(151, 187, 205, 1)',
                          pointBorderColor: '#fff',
                          data: incomeChartData,
                        },
                      ],
                    }}
                  />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
      </Layout>
    </>
  );
}

export default Home;
